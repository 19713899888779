import type { RoleType } from "@prisma/client";
import {
  IconBox,
  IconChartBar,
  IconCheckupList,
  IconFolder,
  IconHome,
  IconNotes,
  IconScale,
  IconSearch,
  IconSpeakerphone,
  IconTrophy,
  IconUserCircle,
  IconUsers,
  IconWallet,
} from "@tabler/icons-react";

type NavigationItem = {
  label: string;
  url: string;
  icon: JSX.Element;
  isActive?: (pathname: string) => boolean;
  disabled?: boolean;
};

export const AFTER_LOGIN_PAGE: Record<RoleType, string> = {
  DESIGNER: "/designer/profile/personal",
  BRAND: "/brand/dashboard",
  ADMIN: "/admin/users",
  REVIEWER: "/reviewer/board",
};

const adminNavLinks: NavigationItem[] = [
  {
    label: "Dashboard & KPIs",
    url: "/admin/dashboard",
    icon: <IconChartBar />,
  },
  {
    label: "Users and roles",
    url: "/admin/users",
    icon: <IconUsers />,
  },
  {
    label: "All concepts",
    url: "/admin/concepts",
    icon: <IconFolder />,
  },
  {
    label: "Projects",
    url: "/admin/projects",
    icon: <IconBox />,
  },
  {
    label: "Design pitches",
    url: "/admin/pitches",
    icon: <IconTrophy />,
  },
  {
    label: "Subscriptions",
    url: "/admin/subscriptions",
    icon: <IconWallet />,
  },
  {
    label: "Legal",
    url: "/admin/legal",
    icon: <IconScale />,
    disabled: true,
  },
  {
    label: "News & releases",
    url: "/admin/news",
    icon: <IconSpeakerphone />,
  },
  {
    label: "Contracts",
    url: "/admin/contracts",
    icon: <IconTrophy />,
    disabled: true,
  },
];

export const navigationLinks: Record<RoleType, NavigationItem[]> = {
  DESIGNER: [
    {
      label: "Dashboard",
      url: "/designer/dashboard",
      icon: <IconHome />,
    },
    {
      label: "My concepts",
      url: "/designer/concepts",
      icon: <IconFolder />,
    },
    {
      label: "Design pitches",
      url: "/designer/pitches",
      icon: <IconTrophy />,
    },
    {
      label: "My Projects",
      url: "/designer/projects",
      icon: <IconBox />,
    },
    {
      label: "Profile",
      url: "/designer/profile/personal",
      icon: <IconUserCircle />,
      isActive: (pathname) => pathname.startsWith("/designer/profile"),
    },
  ],
  BRAND: [
    {
      label: "Dashboard",
      url: "/brand/dashboard",
      icon: <IconHome />,
    },
    {
      label: "Browse the Trust",
      url: "/brand/browse",
      icon: <IconSearch />,
    },
    {
      label: "Designers",
      url: "/brand/designers",
      icon: <IconUsers />,
    },
    {
      label: "My concepts",
      url: "/brand/concepts",
      icon: <IconFolder />,
    },
    {
      label: "My design pitches",
      url: "/brand/pitches",
      icon: <IconTrophy />,
    },
    {
      label: "My projects",
      url: "/brand/projects",
      icon: <IconBox />,
    },
    {
      label: "Profile",
      url: "/brand/profile/general",
      icon: <IconUserCircle />,
      isActive: (pathname: string) => pathname.startsWith("/brand/profile"),
    },
  ],
  REVIEWER: [
    {
      label: "Submitted concepts",
      url: "/reviewer/pool",
      icon: <IconNotes />,
    },
    {
      label: "My review board",
      url: "/reviewer/board",
      icon: <IconCheckupList />,
    },
  ],
  ADMIN: adminNavLinks,
};
